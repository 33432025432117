import { defineStore } from "pinia";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachappAlgemeneCoachStatistieken,
	IomodelsCoachappMijnCoachStatistieken,
	IomodelsFitnessAfspraakExtern,
	IomodelsLidVoorFeed,
} from "@/lib/backend/club.api";
import { ROLE_MAP } from "@/lib/constants/options";
import { useAuthStore } from "@/pinia/auth";

type State = {
	membersPresentWithoutCoach: IomodelsLidVoorFeed[];
	myMembersPresent: IomodelsLidVoorFeed[];
	myMembersAbsentLong: IomodelsLidVoorFeed[];
	statistics: {
		personal: IomodelsCoachappMijnCoachStatistieken;
		general: IomodelsCoachappAlgemeneCoachStatistieken;
	};
	appointments: IomodelsFitnessAfspraakExtern[];
};

export const useCoachStore = defineStore("coach", {
	state: (): State => ({
		membersPresentWithoutCoach: [],
		myMembersPresent: [],
		myMembersAbsentLong: [],
		statistics: {
			personal: {
				aantalLedenUit30GroepGeweest: 0,
				aantalLedenUit30GroepGeholpen: 0,
			},
			general: {
				aantal30ProcentSporters: 0,
				aantal70ProcentSporters: 0,
				totaalAantalGekoppeldeSporters: 0,
			},
		},
		appointments: [],
	}),
	getters: {
		id: () => useAuthStore().userClub?.coachId ?? -1,
		name: () => useAuthStore().userClub?.naam,
		role: () => ROLE_MAP[useAuthStore().role ?? "trainer"],
	},
	actions: {
		async get() {
			await this.getMembersPresentWithoutCoach();
			await this.getMyMembersPresent();
			await this.getMyMembersAbsentLong();
			await this.getPersonalStatistics();
			await this.getGeneralStatistics();
			await this.getAppointments();
		},
		async getMembersPresentWithoutCoach() {
			const response = await clubApi.coachDashboard.getAanwezigeLedenZonderCoach();

			switch (response.status) {
				case 200: {
					// Backend sends duplicates sometimes, remove these.
					this.membersPresentWithoutCoach = response.data.filter(
						(member, index, self) => index === self.findIndex((m) => m.id === member.id),
					);

					return this.membersPresentWithoutCoach;
				}

				default: {
					throw response;
				}
			}
		},
		async getMyMembersPresent() {
			const response = await clubApi.coachDashboard.getMijnAanwezigeLeden(this.id);

			switch (response.status) {
				case 200: {
					this.myMembersPresent = response.data;

					return this.myMembersPresent;
				}

				default: {
					throw response;
				}
			}
		},
		async getMyMembersAbsentLong() {
			const response = await clubApi.coachDashboard.getMijnLangAfwezigeLeden({ coachId: this.id });

			switch (response.status) {
				case 200: {
					this.myMembersAbsentLong = response.data;

					return this.myMembersAbsentLong;
				}

				case 403:
				default: {
					throw response;
				}
			}
		},
		async getPersonalStatistics() {
			const response = await clubApi.coachDashboard.getMijnStatistieken(this.id);

			switch (response.status) {
				case 200: {
					this.statistics.personal = response.data;
					return this.statistics.personal;
				}

				default: {
					throw response;
				}
			}
		},
		async getGeneralStatistics() {
			const response = await clubApi.coachDashboard.getAlgemeneStatistieken(this.id);

			switch (response.status) {
				case 200: {
					this.statistics.general = response.data;
					return this.statistics.general;
				}

				default: {
					throw response;
				}
			}
		},
		async getAppointments() {
			const response = await clubApi.coachDashboard.getMijnAfspraken({ coachId: this.id });

			switch (response.status) {
				case 200: {
					this.appointments = response.data;
					return this.appointments;
				}

				case 403:
				default: {
					throw response;
				}
			}
		},
	},
});

export default useCoachStore;
